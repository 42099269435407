<template>
  <div class="layout-demo">
    <a-layout style="height: 600px;width: 100%;">
      <a-layout-header>
        <a-space size="large">
          <a-switch />
        </a-space>
      </a-layout-header>
      <a-layout-content style="width: 100%;">
        <a-space direction="vertical" size="large" fill>
          <!-- {{ chat_history }} -->
          <a-list
            :virtualListProps="{
              height: 600,
            }"
            :data="chat_history"
          >
            <template #item="{ item, index }">
              <a-list-item :key="index" action-layout="vertical">
                <!-- <a-list-item-meta
                  :title="item.input"
                  :description="item.answer"
                >
                  
                  
                </a-list-item-meta> -->
                <!-- 123123 -->
                <a-card
                      hoverable
                      :style="{ width: '100%', marginBottom: '20px' }"
                    >
                      <div
                        :style="{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }"
                      >
                        <span
                          :style="{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#1D2129',
                          }"
                        >
                          <a-avatar
                            :style="{
                              marginRight: '8px',
                              backgroundColor: '#165DFF',
                            }"
                            :size="28"
                          >
                            Q
                          </a-avatar>
                          <a-typography-text> {{ item.input }}</a-typography-text>
                        </span>
                        <a-link>More</a-link>
                      </div>
                    </a-card>
                    <a-card
                      hoverable
                      :style="{ width: '100%', marginBottom: '20px', backgroundColor: '#ffcccc' }"
                    >
                      <div
                        :style="{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }"
                      >
                        <span
                          :style="{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#1D2129',
                          }"
                        >
                          <a-avatar
                            :style="{
                              marginRight: '24px',
                              backgroundColor: '#165DFF',
                              minWidth: '20px',
                            }"
                            :size="28"
                          >
                            A
                          </a-avatar>
                          <a-typography-text> {{ item.answer }}</a-typography-text>
                        </span>
                        <a-link>More</a-link>
                      </div>
                    </a-card>
              </a-list-item>
            </template>
          </a-list>
        </a-space></a-layout-content
      >
      <a-layout-footer style="width:100%;margin-top:10px;">
        <a-space direction="vertical" size="large" style="width: 100%;">
          <a-input-search
            :style="{ width: '100%' }"
            placeholder="输入prompt"
            button-text="跑"
            :onSearch="chat"
            search-button
          /> </a-space
      ></a-layout-footer>
    </a-layout>
  </div>
</template>
<style scoped>
.layout-demo {
  width: 100%;
  height: 100%;
  margin: 0;
}

</style>

<script>
import enUS from "@arco-design/web-vue/es/locale/lang/en-us";
import { reactive } from "vue";
import { ref } from "vue";
const chat_history = [
  {
    input: "test",
    answer: " Hello! I'm Claude, an AI assistant created by Anthropic.",
  },
];
const  current_chat = {"input":"ss","answer":""}
export default {
  setup() {
    const list = reactive(
      Array(10000)
        .fill(null)
        .map((_, index) => {
          const prefix = `0000${index}`.slice(-5);
          return {
            title: "Beijing Bytedance Technology Co., Ltd.",
            description: `(${prefix}) Beijing ByteDance Technology Co., Ltd. is an enterprise located in China.`,
          };
        })
    );

    const products = ref(null);
    fetch("https://testapi.jasonwatmore.com/products")
      .then((response) => response.json())
      .then((data) => (products.value = data));

    //real const for chat history
    // const chat_history = ref(null);

    return {
      list,
      products,
      // chat_history
    };
  },
  data() {
    return {
      enUS,
      chat_history,
      current_chat
    };
  },
  methods: {
    chat: function (input) {
      // const products = ref(null);
      // fetch("https://testapi.jasonwatmore.com/products/"+input)
      // .then((response) => response.json())
      // .then((data) => (products.value = data));
      //real network
      current_chat.input = input;
      this.chat_history.push(current_chat);
    },
  },
};
</script>
